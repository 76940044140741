import { loadScript, PayPalNamespace } from '@paypal/paypal-js';
import { PayPalScriptOptions } from '@paypal/paypal-js/types/script-options';

export default async ({ $config, store }: { $config: any, store: any}, inject: (key: string, value: any) => void) => {
  let paypalInstance: any = null;
  const loadPayPalScript = async (customerId?: string) => {
    console.log('loading paypal script',store.getters['ship/stepName']);
    if (store.getters['ship/stepName']?.toLowerCase() === 'payment') {
      const options: PayPalScriptOptions = {
        clientId: $config.paypalClientId,
        currency: 'USD',
        vault: true,
        dataUserIdToken: customerId
      };
      try {
        const oldScript = document.querySelector('script[src*="paypal.com/sdk/js"]');
        if (oldScript) oldScript.remove();

        paypalInstance = await loadScript(options);
        console.log('paypal script loaded');
        Object.assign(paypalObject, {
          ...paypalInstance,
          Buttons: paypalInstance.Buttons,
          reload: loadPayPalScript
        });
        console.log('paypal instance', paypalInstance);
        return paypalInstance;

      } catch (error) {
        console.error('Error loading PayPal SDK:', error);
        return null;
      }
    };
  };

    // Crear objeto inicial que será inyectado
  const paypalObject = {
    loadPayPalScript,
    reload: loadPayPalScript
  };
  
  inject('paypal', paypalObject);
};